import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import HomeView from '../views/home/index.vue'
const routes = [{
		path: '/',
		redirect: "/home/homePage"
	},
	{
		path: '/home', //主页
		name: 'home',
		component: HomeView,
		children: [{
				path: 'homePage', //公司介绍
				name: "homePage",
				component: () => import('@/views/homePage/index.vue')
			},
			{
				path: 'introduce', //公司介绍
				name: "introduce",
				component: () => import('@/views/introduce/index.vue')
			},
			{
				path: 'product', //产品介绍
				name: 'product',
				component: () => import('@/views/product/index.vue')
			},
			{
				path: 'solution', //解决方案
				name: 'solution',
				component: () => import('@/views/solution/index.vue')
			},
			{
				path: 'solution1', //解决方案1
				name: 'solution1',
				component: () => import('@/views/solution/solution1/index.vue')
			},
			{
				path: 'solution2', //解决方案1
				name: 'solution2',
				component: () => import('@/views/solution/solution2/index.vue')
			},
			{
				path: 'solution3', //解决方案1
				name: 'solution3',
				component: () => import('@/views/solution/solution3/index.vue')
			},
			{
				path: 'contactUs', //联系我们
				name: 'contactUs',
				component: () => import('@/views/contactUs/index.vue')
			},

		],
	},
	{
		path: "/404", //404页面
		name: "notFindPage",
		component: () => import("@/views/notFindPage/index.vue")
	},
	{
		path: '/:pathMatch(.*)', //404页面
		redirect: '/404',
	},

]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
