import axios from 'axios'
//创建axios实例
const request = axios.create({
	baseURL: process.env.VUE_APP_URL,
	timeout: 60000, //请求超时时间
	headers: {
		post: {
			"Content-Type": "application/x-www-form-urlencoded"
		}
	}
})


// 请求拦截
// request.interceptors.request.use(config => {

// })


//响应拦截
// request.interceptors.response.use(response => {
// 	console.log(response)
// 	return response
// })

export default request
