<template>
	<router-view />
</template>
<script>
	import {
		onMounted
	} from 'vue'
	import {
		setRem
	} from '../src/utils/rem.js'
	export default {
		setup() {
			onMounted(() => {
				setRem();
				// 改变窗口大小时重新设置 rem
				window.addEventListener("resize", setRem);
			})
		}
	}
</script>
<style scoped lang="less">

</style>
