import {
	createApp
} from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import MetaInfo from 'vue-meta-info';
import i18n from "/src/lang/index.js"

// import axios from '@/utils/request'
import 'animate.css'
const app = createApp(App)
// app.config.globalProperties.$axios = axios; //原型上添加
app.use(i18n)
app.use(ElementPlus).use(MetaInfo).use(store).use(router).mount('#app')
