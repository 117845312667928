// 结合 vue.config.js中的postcsstorem进行设置
function setRem () {
  // 设计稿宽度
  const baseWidth = 1920;
  // 窗口宽度
  const clientWidth = document.documentElement.clientWidth
  // scale 缩放比例
  const scale = clientWidth / baseWidth
  document.documentElement.style.fontSize = scale + 'px'
}
export { setRem };
