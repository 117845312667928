<template>
  <div class="home">
    <div id="topNav">
      <div class="left">
        <div>
          <img src="../../assets/icon/logo.png"/>
          <div>
            <span>{{ $t('费尔马科技') }}</span>
          </div>
        </div>
      </div>
      <div class="right">
        <router-link class="nav" to="/home/homePage"><span>{{ $t('首页') }}</span></router-link>
        <router-link class="nav" to="/home/introduce"><span>{{ $t('公司介绍') }}</span></router-link>
        <!-- <router-link class="nav" to="/home/product"><span>产品介绍</span></router-link> -->

        <router-link class="nav" to="/home/solution"><span>{{ $t('解决方案') }}</span></router-link>
        <router-link class="nav" to="/home/contactUs"><span>{{ $t('联系我们') }}</span></router-link>
        <div class="hov nav" to="">
          <span>{{ $t('APP下载') }}</span>
          <div class="jieshao">
            <div class="trigon"></div>
            <div class="flexsBox">
              <div>
                <img src="../../assets/icon/wx.jpg"/>
                <p>“爱渔保”{{ $t('小程序') }}</p>
              </div>
              <div class="imgBox2">
                <img :src="apkUrl"/>
                <p>“饲料帮” APP</p>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <el-button type="warning" @click="$i18n.locale = $i18n.locale === 'zh-cn'?'en':'zh-cn';">中/English</el-button>-->

    </div>
    <div>
      <router-view v-slot="{ Component }">
        <transition name="slide-fade" mode="out-in" :duration="{ enter: 500, leave: 300 }">
          <component :is="Component"/>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import {
  getApp
} from '../../utils/article.js'
import {
  ref,
  onMounted
} from "vue";

export default {
  name: 'HomeView',
  metaInfo() {
    return {
      meta: [{
        name: 'keywords',
        content: '助力养殖'
      },
        {
          name: 'description',
          content: '渔你同行'
        }
      ]
    }
  },
  setup() {
    let apkUrl = ref();
    onMounted(() => {
      getAPK();
    })

    //获取饲料帮apk
    function getAPK() {
      getApp().then((res) => {
        if (res.data.code == 200) {
          apkUrl.value = res.data.data.qrCodeUrl
        }
      })
    }
    return {
      apkUrl
    }
  }
}
</script>
<style lang="less">
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0.3;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  opacity: 1;
}
</style>

<style lang="less" scoped>
@import url(./home.less);
</style>
